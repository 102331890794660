@tailwind base;
@tailwind components;
@tailwind utilities;

.root,
#root,
#docs-root {
  --primary: #e2e2e2; /*#f2ebe5; */
  --secondary: #384d48;
  --button: #acad94;
  --border1: #2b262d;
  --text1: #2b262d;
  --text-2: #9f496e;
  --darkPrimary: #384d48;
  --darkSecondary: #777a92;
  --darkButton: #45a29e;
  --darkBorder1: #66fcf1;
  --darkText1: #e4e5f1;
  --darkText2: #cacde8;
}

body {
  background-color: #2b262d;
}
